body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: #f7f8f7;
    color: #222222;
}

body, html {
  height: 100%;
  width: 100%;
  margin: 0;
}

.grid-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.main-content {
    padding-top: 20px;
    padding-bottom: 20px;

    }

  .content {
    padding: 40px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }

.sidebar {
    margin-top: 20px;
    padding-top: 40px;
    background: #fff;
}

/* Header */
.top-bar {
    background-color: #636363;
    color: #ffffff;
    font-weight: 400;
    text-transform: none;
    font-size: 13px;
}

.top-bar .inside-top-bar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
     padding: 10px 40px;
}

.topbar-content {
  flex: 1 1 auto;
}

/* Navigation */

.site-header, .site-footer {
    background-color: #ffffff;
}

.app-logo {
   padding-left: 50px;
}
.logo {
 max-width: 200px;
}




/* Blog single page */

.post-featured-image{
  max-width: 100%;
  height: auto;
}


/* About page */

.Box {

    border-color: #d0d7de;
    border-radius:6px;
    border-style: solid;
    border-width: 1px;
}

.Box-body a img {
   padding: 2px;
   }



.contact-btn {
    width: 90%;
    background-color: #f6f8fa;
    padding: 5px;
    border-radius: 6px;
    border: 1px solid #1f232826;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
}

.octicon {
    float: left;
    width: 16px;
    margin-top: 4px;
    margin-right: 8px;
    color: #656d76;
    text-align: center;
}

.sidebar .Link--primary {
    color: #222222;
    text-decoration: none;
}